export const amplifyConfig = {
  // aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  // aws_cognito_identity_pool_id:
  //   process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  // aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  // aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  // aws_user_pools_web_client_id:
  //   process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,

  // aws_project_region: 'us-east-1',
  // aws_cognito_identity_pool_id:
  //   'us-east-1:7474fda9-17bb-485c-8e7f-70e3a62cd0ba',
  // aws_cognito_region: 'us-east-1',
  // aws_user_pools_id: 'us-east-1_10yb6hQMr',
  // aws_user_pools_web_client_id: '26f42i2r94umdsakdqu9j775bv',

  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:b8d1b52c-3e50-4631-8f32-d1d3dfd27f36',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_6Cxshlm6w',
  aws_user_pools_web_client_id: '42dun2j0ti5msurti0ecka0vbb',

  social: {
    FB: '463749231944243', // for prod
    // FB: '1651909475145824', // for testing
  },

  API: {
    endpoints: [
      {
        name: 'ctdollar-api', // can be anything - it is refferenced in API calls,
        endpoint:
          'https://3k0plwkpeb.execute-api.us-east-1.amazonaws.com/prod/',
        region: 'us-east-1',
      },
      // {
      //   name: 'ctdollar-api', // can be anything - it is refferenced in API calls,
      //   endpoint:
      //     'https://3vc575rhjh.execute-api.us-east-1.amazonaws.com/prod/',
      //   region: 'us-east-1',
      // },
    ],
  },

  Auth: {
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
  },
};

// cognito: {
//   REGION: "us-east-1",
//   USER_POOL_ID: "us-east-1_TgFK397GZ",
//   APP_CLIENT_ID: "4ns3lfduukk0q1t44516oq5u9d",
//   IDENTITY_POOL_ID: "us-east-1:71a74f6a-fe42-48dc-9fd1-aa2c453e029e",
//   // USER_POOL_ID: "us-east-1_dNVcZSROC",
//   // APP_CLIENT_ID: "25kbtjh39uqk4ej28ih27v2oed",
//   // IDENTITY_POOL_ID: "us-east-1:f848b3c8-7af0-442f-b116-2301b093975c",
// },

// console.log('amplifyConfig:', amplifyConfig);

// const config = {
//   // Backend config
//   s3: {
//     REGION: process.env.REACT_APP_REGION,
//     BUCKET: process.env.REACT_APP_BUCKET,
//   },
//   apiGateway: {
//     REGION: process.env.REACT_APP_REGION,
//     URL: process.env.REACT_APP_API_URL,
//   },
//   cognito: {
//     REGION: process.env.REACT_APP_REGION,
//     USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
//     APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
//     IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
//   },
// };

// export default config;
