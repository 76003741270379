import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth, API } from 'aws-amplify';
import { amplifyConfig } from '../config';
import { User, FbUser } from 'src/models/user';
import { Account } from 'src/models/account';

Amplify.configure(amplifyConfig);

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  account: Account | null;
  fbUser: FbUser | null;
}

interface AuthContextValue extends AuthState {
  method: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
  getAccount: (userId: string) => Promise<void>;
  setAcctData: (acctData: Account) => Promise<void>;
  getFbUser: (fbUser: FbUser) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
};
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type GetAccountAction = {
  type: 'ACCOUNT_DATA';
  payload: {
    account: Account;
  };
};

type SetAcctDataAction = {
  type: 'SET_ACCOUNT_DATA';
  payload: {
    account: Account;
  };
};

type GetFbUserAction = {
  type: 'GET_FB_USER';
  payload: {
    fbUser: FbUser;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | GetAccountAction
  | SetAcctDataAction
  | GetFbUserAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  account: null,
  fbUser: null,
};

const handlers: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: AuthState): AuthState => ({ ...state }),
  VERIFY_CODE: (state: AuthState): AuthState => ({ ...state }),
  RESEND_CODE: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RECOVERY: (state: AuthState): AuthState => ({ ...state }),
  PASSWORD_RESET: (state: AuthState): AuthState => ({ ...state }),
  ACCOUNT_DATA: (state: AuthState, action: GetAccountAction): AuthState => {
    const { account } = action.payload;
    return {
      ...state,
      account,
    };
  },
  SET_ACCOUNT_DATA: (
    state: AuthState,
    action: SetAcctDataAction
  ): AuthState => {
    const { account } = action.payload;
    return {
      ...state,
      account,
    };
  },
  GET_FB_USER: (state: AuthState, action: GetFbUserAction): AuthState => {
    const { fbUser } = action.payload;
    return {
      ...state,
      fbUser,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  getAccount: () => Promise.resolve(),
  setAcctData: () => Promise.resolve(),
  getFbUser: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const getFbUser = async () => {
    let response = null;
    await window.FB.getLoginStatus((status) => {
      // console.log('XXX status:', status);
      if (status.status == 'connected') {
        window.FB.api(
          '/' + status.authResponse.userID,
          'get',
          {
            fields: 'id,email,picture,first_name,last_name',
          },
          (resp) => {
            // console.log('ZZZ response:', resp);
            response = resp;

            dispatch({
              type: 'GET_FB_USER',
              payload: {
                fbUser: {
                  userEmail: response.email,
                  userId: response.id,
                  firstName: response.first_name,
                  lastName: response.last_name,
                  picture:
                    response.picture && response.picture.data
                      ? response.picture.data.url
                      : '',
                },
              },
            });
          }
        );
      }
    });
    // dispatch({
    //   type: 'GET_FB_USER',
    //   payload: {
    //     fbUser: {
    //       userEmail: response.email,
    //       userId: response.id,
    //       firstName: response.first_name,
    //       lastName: response.last_name,
    //       picture:
    //         response.picture && response.picture.data
    //           ? response.picture.data.url
    //           : '',
    //     },
    //   },
    // });
  };

  const login = async (email: string, password: string): Promise<void> => {
    const user = await Auth.signIn(email, password);
    // console.log('LOGIN user:', user);
    // const userId = user.username;
    if (user.challengeName) {
      console.error(`Can't login, "${user.challengeName}" failed.`);
      return;
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          userId: user.username,
          userEmail: user.attributes.email,
          emailVerified: user.attributes.email_verified,
        },
      },
    });
  };

  const getAccount = async (userId: string): Promise<void> => {
    try {
      const data = await API.get('ctdollar-api', `account/${userId}`, {});
      if (data.length > 0) {
        const {
          address,
          email,
          balance,
          userId,
          publicKey,
          conditions,
          notify,
          transactions,
        } = data[0];
        const dataObj = {
          address: address || '',
          email: email || '',
          balance: balance || 0,
          userId: userId || '',
          publicKey: publicKey || '',
          conditions: conditions || [],
          notify: notify || [],
          transactions: transactions || [],
        };
        dispatch({
          type: 'ACCOUNT_DATA',
          payload: {
            account: dataObj,
          },
        });
      }
    } catch (err) {
      console.error('ERROR:', err);
    }
  };

  const setAcctData = async (acctData: Account): Promise<void> => {
    dispatch({
      type: 'SET_ACCOUNT_DATA',
      payload: {
        account: acctData,
      },
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    dispatch({
      type: 'LOGOUT',
    });
    dispatch({
      type: 'ACCOUNT_DATA',
      payload: {
        account: null,
      },
    });
  };

  const register = async (email: string, password: string): Promise<void> => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email },
    });
    dispatch({
      type: 'REGISTER',
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE',
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE',
    });
  };

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY',
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        getAccount,
        setAcctData,
        getFbUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
