import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
// import Authenticated from 'src/components/user/Authenticated';
// import NotAuthenticated from 'src/components/user/NotAuthenticated';
// import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
// import NoSideBarLayout from 'src/layouts/AccentHeaderLayout/NoSideBarLayout';
import SuspenseLoader from 'src/components/app/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Home = Loader(lazy(() => import('src/components/Home')));
const AcctHome = Loader(lazy(() => import('src/components/account/AcctHome')));
const AcctSettings = Loader(
  lazy(() => import('src/components/account/AcctSettings'))
);
const Login = Loader(lazy(() => import('src/components/user/Login')));
const Register = Loader(lazy(() => import('src/components/user/Register')));
const ResetPassword = Loader(
  lazy(() => import('src/components/user/ResetPassword'))
);
// const FacebookUser = Loader(
//   lazy(() => import('src/components/user/FacebookUser'))
// );
const FreeCoins = Loader(lazy(() => import('src/components/user/FreeCoins')));
const CryptoGenerateKeys = Loader(
  lazy(() => import('src/components/crypto/GenerateKeys'))
);
const CryptoBuyCoins = Loader(
  lazy(() => import('src/components/crypto/BuyCoins'))
);
const CryptoBuySuccess = Loader(
  lazy(() => import('src/components/crypto/BuySuccess'))
);
const CryptoBuyCanceled = Loader(
  lazy(() => import('src/components/crypto/BuyCanceled'))
);
const CryptoSendCoins = Loader(
  lazy(() => import('src/components/crypto/SendCoins'))
);
const CryptoBalance = Loader(
  lazy(() => import('src/components/crypto/Balance'))
);
const GeneralAbout = Loader(lazy(() => import('src/components/general/About')));
const GeneralTerms = Loader(lazy(() => import('src/components/general/Terms')));
const PrivacyPolicy = Loader(
  lazy(() => import('src/components/general/PrivacyPolicy'))
);

const GeneralContact = Loader(
  lazy(() => import('src/components/general/Contact'))
);

const routes: PartialRouteObject[] = [
  {
    path: 'user',
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'reset',
        element: <ResetPassword />,
      },
    ],
  },

  // path: 'boxed-sidebar/dashboards',
  // element: (
  //   <Authenticated>
  //     <BoxedSidebarLayout />
  //   </Authenticated>
  // ),

  {
    path: '*',
    element: <AccentHeaderLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about',
        element: <GeneralAbout />,
      },
      {
        path: '/terms',
        element: <GeneralTerms />,
      },
      {
        path: '/privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: '/contact',
        element: <GeneralContact />,
      },
      // {
      //   path: 'free',
      //   element: <FacebookUser />,
      // },
      {
        path: 'free',
        element: <FreeCoins />,
      },
      {
        path: 'crypto',
        children: [
          // {
          //   path: '/',
          //   element: <Navigate to="hospital" replace />,
          // },
          {
            path: 'generatekeys',
            element: <CryptoGenerateKeys />,
          },
          {
            path: 'buycoins',
            element: <CryptoBuyCoins />,
          },
          {
            path: 'buysuccess',
            element: <CryptoBuySuccess />,
          },
          {
            path: 'buycanceled',
            element: <CryptoBuyCanceled />,
          },
          {
            path: 'sendcoins',
            element: <CryptoSendCoins />,
          },
          {
            path: 'balance',
            element: <CryptoBalance />,
          },
        ],
      },
      {
        path: 'account',
        children: [
          {
            path: '/',
            element: <AcctHome />,
          },
          {
            path: 'settings',
            element: <AcctSettings />,
          },
        ],
      },

      // {
      //   path: 'overview',
      //   element: <Navigate to="/" replace />,
      // },
      // {
      //   path: 'status',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Navigate to="404" replace />,
      //     },
      //     {
      //       path: '404',
      //       element: <Status404 />,
      //     },
      //     {
      //       path: '500',
      //       element: <Status500 />,
      //     },
      //     {
      //       path: 'maintenance',
      //       element: <StatusMaintenance />,
      //     },
      //     {
      //       path: 'coming-soon',
      //       element: <StatusComingSoon />,
      //     },
      //   ],
      // },
      // {
      //   path: '*',
      //   element: <Status404 />,
      // },
    ],
  },
];

export default routes;
