import { ReactNode } from 'react';
import HomeToneIcon from '@mui/icons-material/HomeTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ShopTwoToneIcon from '@mui/icons-material/ShopTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import ImportContactsTwoToneIcon from '@mui/icons-material/ImportContactsTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import FacebookIcon from './fbIcon';
import AutoAwesomeTwoToneIcon from '@mui/icons-material/AutoAwesomeTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Home',
        link: '/',
        icon: HomeToneIcon,
      },
      {
        name: 'CT$ Account',
        link: '/account',
        icon: AccountBalanceTwoToneIcon,
      },
      {
        name: 'Buy CT$',
        link: '/crypto/buycoins',
        icon: ShopTwoToneIcon,
      },
      {
        name: 'Send CT$',
        link: '/crypto/sendcoins',
        icon: SendTwoToneIcon,
      },
      {
        name: 'Check Balance',
        link: '/crypto/balance',
        icon: AccountBalanceWalletTwoToneIcon,
      },
      {
        name: 'Generate Keys',
        link: '/crypto/generatekeys',
        icon: VpnKeyTwoToneIcon,
      },
      {
        name: 'Get CT$100 for FREE',
        link: '/free',
        icon: AutoAwesomeTwoToneIcon,
      },
    ],
  },
  {
    heading: 'This Site',
    items: [
      {
        name: 'About Us',
        icon: ImportContactsTwoToneIcon,
        link: '/about',
      },
      {
        name: 'Terms & Conditions',
        icon: WarningTwoToneIcon,
        link: '/terms',
      },
      {
        name: 'Privacy Policy',
        icon: WarningTwoToneIcon,
        link: '/privacy',
      },
      {
        name: 'Contact Us',
        icon: EmailTwoToneIcon,
        link: '/contact',
      },
      {
        name: 'Auth Pages',
        icon: AccountBoxTwoToneIcon,
        link: '/auth',
        items: [
          {
            name: 'Login',
            link: '/user/login',
          },
          {
            name: 'Register',
            link: '/user/register',
          },
          // {
          //   name: 'Recover Password',
          //   link: '/user/reset',
          // },
        ],
      },
    ],
  },
];

export default menuItems;
